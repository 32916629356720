import type { IDealValidationInput } from '@eo/graphql-types'

export const mapToValidateObject = (
  data,
  orderType: string,
): IDealValidationInput => ({
  amount: parseFloat(data.BRQ_AMOUNT),
  description: data.BRQ_DESCRIPTION,
  invoice: data.BRQ_INVOICENUMBER,
  payerHash: data.BRQ_PAYER_HASH,
  paymentId: data.BRQ_PAYMENT,
  bic: data.BRQ_SERVICE_IDEAL_CONSUMERBIC,
  iban: data.BRQ_SERVICE_IDEAL_CONSUMERIBAN,
  issuer: data.BRQ_SERVICE_IDEAL_CONSUMERISSUER,
  customer: data.BRQ_CUSTOMER_NAME,
  transactionId: data.BRQ_SERVICE_IDEAL_TRANSACTIONID,
  ...(data.BRQ_STARTRECURRENT && { startRecurrent: data.BRQ_STARTRECURRENT }), // not always given
  statusCode: parseInt(data.BRQ_STATUSCODE, 10),
  statusCodeDetail: data.BRQ_STATUSCODE_DETAIL,
  signature: data.BRQ_SIGNATURE,
  statusMessage: data.BRQ_STATUSMESSAGE,
  isTest: !!data.BRQ_TEST,
  timeStamp: data.BRQ_TIMESTAMP,
  transactions: data.BRQ_TRANSACTIONS,
  customFormState: data.CUST_FORMSTATE || '',
  ...(data.CUST_ORDERID && { orderId: data.CUST_ORDERID }),
  orderType: orderType || '',
  customPaymentData: data.CUST_PAYMENTDATA || '',
  customPersonData: data.CUST_PERSONDATA || '',
  customProductData: data.CUST_PRODUCTDATA || '',
  ...(data.CUST_PURCHASEID && { purchaseId: data.CUST_PURCHASEID }),
})
